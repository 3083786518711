import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Find out when websites publish new requests for proposals & be the first to respond to RFP Alerts "
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>First of all, what is a request for a proposal? A company will probably issue an RFP when looking for a service provider or
            vendor to work with, and the company issuing the RFP is the one writing it. The RFP helps companies to quickly evaluate
            the relative strengths and weaknesses of a vendor in relation to a project and helps them in choosing their suppliers.
            </p>
            <p>By using an RFP, a company can also get a look at the different strategies proposed by the bidding vendors.
            This can be enlightening because a company may see strategies they had never considered before, and all of this happens
                   before the company commits to a vendor.</p>

            <h4>Monitor potential clients & be the first to respond to RFP requests</h4>

            <p>If you are a company or vendor whose business depends on finding and responding to RFPs,
            you know that it is a long arduous process of checking the RFP pages of your potential prospects,
            and filling in detailed forms and waiting at length for a response. In addition, manually checking for newly issued
            RFPs is time-consuming, and it can divert attention away from your business.
            </p>
            <p>
              Use Hexowatch to get notifications whenever the government or another entity issues a new RFP. This will help you to
              save the time and energy you need for other parts of the RFP process.
            </p>
            <h4>Get Notifications When Your Existing Contract Is Up For Renewal</h4>
            <p>A lot of businesses are obligated to apply for public RFPs even if they have already been chosen as the preferred vendor.
            It is just a formality, but it can be costly if the deadline for renewal is missed through an administrative oversight.
            </p>
            <p>If or when your business relies on a renewal such as this, use a tool like Hexowatch to stay on top of important deadlines
              by monitoring the RFP pages of your key partners and clients.</p>

            <h4>Go beyond RFP monitoring</h4>
            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every change and
              turn any website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
